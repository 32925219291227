const truncate = (fullStr, strLen, separator) => {
    if (fullStr.length <= strLen) return fullStr;

    separator = separator || '...';

    const sepLen = separator.length;
    const charsToShow = strLen - sepLen;
    const frontChars = Math.ceil(charsToShow/2);
    const backChars = Math.floor(charsToShow/2);

    return fullStr.substr(0, frontChars) + 
           separator + 
           fullStr.substr(fullStr.length - backChars);
};

const ConnectedWallet = ({wallet}) => {
  return (
    <button className="homenav-button connect" href="#"><span className="dot bg-success"></span> {truncate(wallet, 12, "...")}</button>
  )
}

export default ConnectedWallet;
