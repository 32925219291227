import {Container, Row, Nav} from 'react-bootstrap';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';
import { SiTwitter, SiDiscord } from 'react-icons/si';
import { WalletHook } from "../utils/ethereum";
import WalletConnection from '../components/blockchain/WalletConnection';
import ImageLogo from '../assets/images/logo.png';
import ImageOpenSea from '../assets/images/opensea.png';
import './HomeNavbar.css';

const HomeNavbar = ({showConnect}) => {

    const { wallet, chain } = WalletHook();
    
    return (
        <Container>
            <Row>
                <Navbar bg="transparent" expand="lg" sticky="top" className="home-navbar">
                    <Container>
                        <Link className="navbar-brand" to="/" title="Home"><img src={ImageLogo} className="logo" alt="Logo" height={84}/></Link>
                        {showConnect &&
                            <Navbar className="ms-auto d-lg-none">
                                <WalletConnection wallet={wallet} chain={chain} />
                            </Navbar>
                        }
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav>
                                <Link className="homenav-button nav-link" to="/team" title="Team">Meet the team</Link>
                                <Link className="homenav-button" to="/mint" title="Mint">Mint</Link>
                            </Nav>
                            <Nav className="socials me-auto">
                                <Nav.Link className="social twitter" href={process.env.REACT_APP_TWITTER} target="_blank"><SiTwitter /></Nav.Link>
                                <Nav.Link className="social discord" href={process.env.REACT_APP_DISCORD} target="_blank"><SiDiscord /></Nav.Link>
                                <Nav.Link className="social opensea" href={process.env.REACT_APP_OPENSEA} target="_blank"><img src={ImageOpenSea} alt="OpenSea" height={18} /></Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                        {showConnect &&
                            <Navbar className="ms-auto d-none d-lg-inline-block">
                                <WalletConnection wallet={wallet} chain={chain} />
                            </Navbar>
                        }
                    </Container>
                </Navbar>
            </Row>
        </Container>
    )
}

export default HomeNavbar;
