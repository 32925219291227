import {Container} from 'react-bootstrap';
import Navbar from 'react-bootstrap/Navbar';
import ImageBackground from '../assets/images/background-home.png';
import ImagePreview from '../assets/images/vip-preview.gif';
import HomeNavbar from '../components/HomeNavbar';
import { SiTwitter, SiDiscord } from 'react-icons/si';
import ImageOpenSea from '../assets/images/opensea.png';
import './Home.css';

const Home = () => {
    return (
        <div>
            <HomeNavbar/>
            <div className="contentMain">
                <img src={ImageBackground} alt="Background" className="background"/>
                <img src={ImagePreview} alt="" className="preview" />
            </div>
            <Navbar bg="transparent" expand="lg" sticky="bottom" className="home-navbar-social d-block d-lg-none">
                <Container>
                    <a className="social twitter" href={process.env.REACT_APP_TWITTER} target="_blank" rel="noreferrer"><SiTwitter /></a>
                    <a className="social discord" href={process.env.REACT_APP_DISCORD} target="_blank" rel="noreferrer"><SiDiscord /></a>
                    <a className="social opensea" href={process.env.REACT_APP_OPENSEA} target="_blank" rel="noreferrer"><img src={ImageOpenSea} alt="OpenSea" height={20} /></a>
                </Container>
            </Navbar>
        </div>
    )
}

export default Home;