import {Link} from "react-router-dom";
import {Container} from 'react-bootstrap';

import './FooterLinks.css';

const FooterLinks = () => {
    return (
        <Container className="text-end footerLinks">
            <Link to="/terms">Terms and Conditions</Link>
            | <Link to="/privacy">Privacy Policy</Link>
        </Container>
    )
}

export default FooterLinks;
