import {useState} from 'react';
import {Container} from 'react-bootstrap';
import HomeNavbar from '../components/HomeNavbar';
import FooterLinks from '../components/FooterLinks';
import { whitelistCount, getRootHash, getMerkleProof } from "../utils/whitelist";
import './Whitelist.css';

const Whitelist = () => {

    const [address, setAddress] = useState('');
    const [merkleResult, setMerkleResult] = useState('');

    const handleChange = event => {
        setAddress(event.target.value);
    };

    const handleClick = event => {
        event.preventDefault();

        const merkleProof = getMerkleProof(address);

        if (merkleProof !== null) {
            const formattedProofString = "[" + merkleProof.map(x => "\"" + x + "\"").toString() + "]";
            setMerkleResult(formattedProofString);
        } else {
            setMerkleResult(`${address} is not whitelisted.`);
        }
    };
    
    return (
        <div>
            <HomeNavbar/>
            <Container className="mt-5 mb-5">
                <h1>Whitelist Verification</h1>
                <p className="mt-3">Maximum Supply: {process.env.REACT_APP_NFT_MAX_SUPPLY}</p>
                <p>Whitelist Supply: {process.env.REACT_APP_NFT_WHITELIST_SUPPLY}</p>
                <p>Number of Whitelisted Addresses: {whitelistCount()}</p>
                <p className="text-break">Merkle Tree Root Hash: {getRootHash()}</p>

                <input
                    type="text"
                    id="address"
                    name="address"
                    onChange={handleChange}
                    value={address}
                    autoComplete="off"
                    placeholder="Address Here"
                />
                <button onClick={handleClick}>Generate Proof for Address</button>
                <p className="mt-3 text-break">Merkle Proof Result: {merkleResult}</p>
            </Container>
            <FooterLinks/>
        </div>
    )
}

export default Whitelist;