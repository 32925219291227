import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Team from "./pages/Team";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
// import Mint from "./pages/Mint";
import MintSoon from "./pages/MintSoon";
import Whitelist from "./pages/Whitelist";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <Router>
      <ToastContainer position="top-center" />
      <Routes>
        <Route path="/" exact element={<Home/>} />
        <Route path="/terms" exact element={<Terms/>} />
        <Route path="/privacy" exact element={<Privacy/>} />
        <Route path="/team" exact element={<Team/>} />
        {/* <Route path="/mint" exact element={<Mint/>} /> */}
        <Route path="/mint" exact element={<MintSoon/>} />
        <Route path="/whitelist" exact element={<Whitelist/>} />
        <Route path="*" element={<Home/>} />
      </Routes>
    </Router>
  );
}

export default App;
