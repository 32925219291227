import {Container} from 'react-bootstrap';
import HomeNavbar from '../components/HomeNavbar';
import FooterLinks from '../components/FooterLinks';

const Privacy = () => {
    return (
        <div>
            <HomeNavbar/>
            <Container className="mt-5 mb-5">
                <h1>Privacy Policy</h1>
                <p>Commodo ea ex ipsum ex cupidatat sint nulla et. Proident anim ex proident ipsum deserunt fugiat ut aliquip occaecat ipsum. Pariatur officia veniam voluptate nulla dolore reprehenderit tempor est reprehenderit ut. Lorem velit magna reprehenderit velit laborum eiusmod duis occaecat consequat.</p>
                <p>Excepteur officia velit consequat pariatur do amet magna dolor nulla laboris aliqua cillum. Commodo consectetur exercitation culpa quis sit Lorem ad consequat laborum consectetur occaecat. Veniam do ad quis nostrud pariatur adipisicing anim id quis tempor aliqua. Sint dolor tempor do do occaecat cillum et amet amet irure ipsum. Quis ut reprehenderit magna reprehenderit sint non voluptate consectetur fugiat irure est ut laborum.</p>
            </Container>
            <FooterLinks/>
        </div>
    )
}

export default Privacy;