import MainnetConnect from "./MainnetConnect";
import MetamaskConnect from "./MetamaskConnect";
import ConnectedWallet from "./ConnectedWallet";

const WalletConnection = ({ wallet, chain }) => {

    return (
        wallet ? chain === process.env.REACT_APP_CHAIN ? <ConnectedWallet wallet={wallet} /> : <MainnetConnect /> : <MetamaskConnect />
    );
};

export default WalletConnection;
