import {Container, Row, Col} from 'react-bootstrap';
import HomeNavbar from '../components/HomeNavbar';
import FooterLinks from '../components/FooterLinks';
import './Team.css';
import { SiTwitter } from 'react-icons/si';
import ImageDanojean from '../assets/images/team/danojean.png';
import ImageMator from '../assets/images/team/mator.png';
import ImageJeanw from '../assets/images/team/jeanw.png';
import ImageMatt from '../assets/images/team/matt.png';

const Team = () => {
    return (
        <div className="teamContainer">
            <HomeNavbar/>
            <Container className="team mt-5">
                <Row>
                    <Col lg={3} md={4}>
                        <img className="profile" src={ImageDanojean} alt="DanoJean" />
                        <div className="person">
                            <div className="name">DanoJean</div>
                            <div className="title">Co-Founder</div>
                            <p>Actuarial science student with a love for trading crypto. Working towards building a fierce community of traders to squeeze as much as possible out of the markets.</p>
                        </div>
                        <div className="links">
                            <a href="https://twitter.com/danojeanTL" title="@danojeanTL" target="_blank" rel="noreferrer" className="twitter"><SiTwitter /></a>
                        </div>
                    </Col>
                    <Col lg={3} md={4}>
                        <img className="profile" src={ImageMator} alt="Mator" />
                        <div className="person">
                            <div className="name">MatoR</div>
                            <div className="title">Co-Founder</div>
                            <p>Full time actuarial science student with a keen interest in price action trading across crypto assets and index futures. Initially only traded crypto however have also developed a curiosity for the field of decentralized finance.</p>
                        </div>
                        <div className="links">
                            <a href="https://twitter.com/Mato_RM02" title="@Mato_RM02" target="_blank" rel="noreferrer" className="twitter"><SiTwitter /></a>
                        </div>
                    </Col>
                    <Col lg={3} md={4}>
                        <img className="profile" src={ImageJeanw} alt="Jeanw" />
                        <div className="person">
                            <div className="name">Jeanw</div>
                            <div className="title">Co-Founder</div>
                            <p>Full time price action crypto, forex and indices day trader, full time student and ex sneaker reseller and bot middleman. Passionate about actuarial science, crypto/web3 and the future of decentralised finance.</p>
                        </div>
                        <div className="links">
                            <a href="https://twitter.com/ftfpjeanw" title="@ftfpjeanw" target="_blank" rel="noreferrer" className="twitter"><SiTwitter /></a>
                        </div>
                    </Col>
                    <Col lg={3} md={4}>
                        <img className="profile" src={ImageMatt} alt="Matt" />
                        <div className="person">
                            <div className="name">Matt</div>
                            <div className="title">Co-Founder</div>
                            <p>Actuarial science student and fintech fanatic. Strong proponent for widespread crypto application in finance, and even a BIGGER fan of price. Focused on building Linesman into the most valuable platform throughout the crypto space!</p>
                        </div>
                        <div className="links">
                            <a href="https://twitter.com/TraderMGG" title="@TraderMGG" target="_blank" rel="noreferrer" className="twitter"><SiTwitter /></a>
                        </div>
                    </Col>
                </Row>
            </Container>
            {/* <FooterLinks/> */}
        </div>
    )
}

export default Team;