import React from 'react';
import { connectMetamask } from '../../utils/ethereum';

const MetamaskConnect = () => {
  return (
    <button onClick={connectMetamask} className="homenav-button connect" href="#"><span className="dot"></span> Connect wallet</button>
  )
}

export default MetamaskConnect;
