import {Container} from 'react-bootstrap';
import HomeNavbar from '../components/HomeNavbar';
import FooterLinks from '../components/FooterLinks';
import './Mint.css';

const MintSoon = () => {

    return (
        <div className="mintContainer">
            <HomeNavbar showConnect />
            <Container className="mintWrapper mt-2 mb-5 text-center">
                <div className="mintPanther">
                    <div className="mint d-flex pt-5 pb-5" style={{minHeight: '450px'}}>
                        <h1 className="mx-auto my-auto">Coming Soon</h1>
                    </div>
                </div>
            </Container>
            {/* <FooterLinks/> */}
        </div>
    )
}

export default MintSoon;