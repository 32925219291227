const { MerkleTree } = require('merkletreejs');
const keccak256 = require('keccak256');

// TODO: set list of updated wallet addresses
// Collect list of wallet addresses
const whitelistAddresses = [
    "0x03c760995BA84c2b87594eff22565Ba8D482C442",
    "0x3E4B2a4e6225169bAE82462982280682a6d26aF3",
    "0x379767798E4CC48AEab60d0195aC2D4C8B9EcA90"
  ];

// Create a new array of `leafNodes` by hashing all indexes of the `whitelistAddresses`
// using `keccak256`. Then creates a Merkle Tree object using keccak256 as the algorithm.
//
// The leaves, merkleTree, and rootHas are all PRE-DETERMINED prior to whitelist claim
const leafNodes = whitelistAddresses.map(addr => keccak256(addr));
const merkleTree = new MerkleTree(leafNodes, keccak256, { sortPairs: true});

// Get root hash of the `merkleTree` in hexadecimal format (0x)
const rootHash = merkleTree.getRoot();
const buf2hex = x => '0x' + x.toString('hex');
const rootHashHex = buf2hex(rootHash);

export const whitelistCount = () => {
    return whitelistAddresses.length;
};

export const getRootHash = () => {
    return rootHashHex;
};

export const getMerkleProof = (address) => {
    const claimingAddress = keccak256(address);

    // `getHexProof` returns the neighbour leaf and all parent nodes hashes that will
    // be required to derive the Merkle Trees root hash.
    const hexProof = merkleTree.getHexProof(claimingAddress);

    // Verify if claiming address is in the merkle tree or not.
    if(merkleTree.verify(hexProof, claimingAddress, rootHash)) {
        return hexProof;
    } else {
        return null;
    }
};